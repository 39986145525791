<template>
	<div
			v-if="user.data && user.data.admin"
			class="orders"
	>
		<div class="list">
			<div class="tools">
				<v-btn color="primary" rounded @click="startNewOrder">Add</v-btn>
			</div>
			<v-list>
				<v-list-item
            class="order-item"
            v-for="o in ordersList"
            :key="o.order.userID"
						@click="selectedOrder = orders[o.order.orderID]">
					<div class="date">{{ o.order.dateLocal }}</div>
					<div class="sub" v-if="o.company">{{ o.company.practiceName }}</div>
					<!--          <div class="sub">{{ o.order.grandTotal ? '$' + formatMoney(o.order.grandTotal) : '' }}</div>-->
				</v-list-item>
			</v-list>
		</div>
		<div class="form" v-if="hasSelectedOrder">
			<div class="tools">
				<v-btn color="success" @click="saveOrder">Save</v-btn>
				<v-btn color="error" @click="deleteOrder">Delete</v-btn>
			</div>

			<v-autocomplete label="Company" :items="companyList" v-model="selectedOrder.companyID" @input="changeOrderCompany"/>
			<!-- Only show after choosing company -->
			<template v-if="selectedOrder.companyID">
				<v-radio-group
						v-model="dateType"
						row
				>
					<v-radio
							label="Order Date"
							value="order"
					></v-radio>
					<v-radio
							label="Due Date"
							value="due"
					></v-radio>
				</v-radio-group>

				<v-date-picker :value="dateValue"
						@input="setOrderDate"/>
				<div class="section-title section-title--small">To:</div>
				<div class="field" v-for="(line, i) in selectedOrder.to" :key="`${i}line`">
					<v-text-field dense :label="`line: ${i + 1}`" v-model="selectedOrder.to[i]"/>
					<v-icon small class="remove fal fa-times" @click="removeFromArray(selectedOrder.to, i)"/>
				</div>
				<v-btn color="primary" x-small rounded @click="addToLine">Add line</v-btn>

				<div class="section-title">Items:</div>
				<div class="field" v-for="(item, ii) in selectedOrder.items" :key="ii + 'item'">
					<v-select label="Product" :items="possibleProducts" dense :value="selectedOrder.items[ii].id"
							@input="changeOrderItemType(ii, $event)"/>
					<v-text-field label="Quantity" type="number" class="count" dense v-model="selectedOrder.items[ii].count"/>
					<v-icon small class="remove fal fa-times" @click="removeFromArray(selectedOrder.items, ii)"/>
				</div>
				<v-btn color="primary" x-small rounded @click="addItem">Add Item</v-btn>
				<div class="section-title">Shipping:</div>
				<div class="field">
					<v-text-field label="Description" v-model="selectedOrder.shipping.description"/>
					<v-text-field label="Price" class="ml-2" type="number" v-model.number="selectedOrder.shipping.price"/>
				</div>
				<div class="section-title">Terms</div>
				<div class="field">
					<v-text-field label="What Terms" class="ml-2" v-model="selectedOrder.terms"/>
				</div>
				<div class="section-title">Special Instructions:</div>
				<div class="field">
					<v-text-field label="Note" class="ml-2" v-model="selectedOrder.note"/>
				</div>
			</template>

		</div>
		<div class="invoice__container">
			<div class="meta-container">
				<div class="email-to-send" v-if="selectedOrder.email">Send to: <strong>{{ selectedOrder.email }}</strong></div>

				<div class="order-internal-notes" v-if="selectedOrder.internalNotes">
					<strong>Special Instructions</strong><br>
					{{ selectedOrder.internalNotes }}
				</div>
			</div>

			<Invoice
					v-if="hasSelectedOrder && selectedOrder.companyID"
					:to="selectedOrder.to"
					:invoice-num="selectedOrder.invoiceNum"
					:date="selectedOrder.dateLocal"
					:dueDate="selectedOrder.dueDateLocal"
					:items="selectedOrder.items"
					:shipping="shippingFormatted"
					:sub-total="selectedOrder.subTotal"
					:grand-total="selectedOrder.grandTotal"
					:email="selectedOrder.email"
					:fileName="fileName"
					:rebate="selectedOrder.rebate"
					:totalCC="selectedOrder.totalCC"
					:selectedCompanyInfo="selectedCompanyInfo"
					:note="selectedOrder.note"
					:terms="selectedOrder.terms"
			/>
		</div>
	</div>
	<div class="missing-error" v-else>
		Missing permissions, maybe you meant to go to
		<v-btn color="primary" @click="$router.push('/app/your-orders')">Your order page?</v-btn>
	</div>
</template>

<script>
import Invoice from "@/components/Orders/Invoice";
import { parse } from 'date-fns';
import { uuid } from "@/helpers/uuid";
import { analytics, db } from "@/helpers/firebase";
import { store } from "@/helpers/store";
import { pricing } from "@/components/pricing";

export default {
	name: 'PrivateOrders',
	metaInfo: {
		// if no subcomponents specify a metaInfo.title, this title will be used
		title: 'EMS Bio Orders',
		meta: [
			{
				name: 'description',
				content: 'Place new orders and see previous ones.'
			},
		],
		// all titles will be injected into this template
		titleTemplate: '%s'
	},
	props: {},
	components: {
		Invoice,
	},
	data() {
		return {
			dateType: 'order',
			user: {},
			orders: {},
			newOrderIDs: {}, // track if the order being saved is new
			companies: {},
			selectedOrder: {},
			pricing,
		};
	},
	beforeDestroy() {
		store.unwatch('systemUser', this.setUser);
	},
	created() {
		store.watch('systemUser', this.setUser);
		this.setUser(store.get('systemUser'));

		db.collection("companies")
				.get()
				.then(querySnapshot => {
					querySnapshot.forEach(doc => {
						// doc.data() is never undefined for query doc snapshots
						this.$set(this.companies, doc.id, doc.data());
					});
				})
				.catch(function(error) {
					console.log("Error getting companies: ", error);
				});

		db.collection("orders")
				.get()
				.then(querySnapshot => {
					querySnapshot.forEach(doc => {
						// doc.data() is never undefined for query doc snapshots
						this.$set(this.orders, doc.id, doc.data());
					});
				})
				.catch(function(error) {
					console.log("Error getting orders: ", error);
				});

	},
	methods: {
		// comes from the store so watch for .new
		setUser(user) {
			this.$set(this, 'user', user.new || user);
		},
		calculateItems() {
			const toReturn = {
				subTotal: 0,
				grandTotal: 0,
				rebate: 0,
				totalCC: 0,
			};

			this.selectedOrder.items.forEach(item => {
				const cost = (item.count || 1) * (item.unitPrice || 0);
				// toReturn.itemsByCost.push(Object.assign({}, item, {
				//   unitPriceFormatted: item.unitPrice ? this.formatMoney(item.unitPrice) : 0,
				//   cost: cost ? this.formatMoney(cost) : 0
				// }));

				toReturn.totalCC += (item.cc * item.count);

        toReturn.rebate += (item.cc * item.rebatePerCC * item.count) || 0;

				toReturn.subTotal += cost;
			})

			// toReturn.rebate = (toReturn.totalCC * item.rebatePerCC) || 0;

			toReturn.grandTotal = toReturn.subTotal - toReturn.rebate + (this.selectedOrder?.shipping?.price || 0);

			Object.keys(toReturn).forEach(key => {
				this.$set(this.selectedOrder, key, toReturn[key]);
			})


		},
		deleteOrder() {
			const doit = window.confirm('Are you sure you want to delete this order?  There\'s no going back.');

			if (doit) {
				db.collection("orders")
						.doc(this.selectedOrder.orderID)
						.delete()
						.then(() => {
							this.$delete(this.orders, this.selectedOrder.orderID);
							this.selectedOrder = {};
						})
						.catch(error => {
							alert('There was a problem deleting, please open your console and contact Rustin');
							console.error(error);
						});
			}
		},
		saveOrder() {
			db.collection('orders')
					.doc(this.selectedOrder.orderID)
					.set(this.selectedOrder, { merge: true })
					.then(() => {
						this.$set(this.orders, this.selectedOrder.orderID, this.selectedOrder);
						this.selectedOrder = {};
						if (this.newOrderIDs[this.selectedOrder.orderID]) {
							delete this.newOrderIDs[this.selectedOrder.orderID];
							analytics.logEvent('new order created');
						}
					});
		},
		formatMoney(num) {
			return Number((num || 1)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
		},
		changeOrderCompany(e) {
			const to = [];
			const company = this.companies[e]
			if (company.practiceContactName) to.push(`Attention: ${company.practiceContactName}`);
			if (company.practiceName) to.push(company.practiceName);
			if (company.practiceStreet) to.push(company.practiceStreet);
			if (company.practiceCity || company.practiceStreet || company.practiceZip) {
				const str = `${company.practiceCity + ', '}${company.practiceState} ${company.practiceZip}`;
				to.push(str);
			}

			this.selectedOrder.to = to;
		},
		addItem() {
			this.selectedOrder.items.push({
				count: 1,
			})
		},
		changeOrderItemType(index, e) {
      Object.keys(this.pricing[e]).forEach(key => {
        this.$set(this.selectedOrder.items[index], key, this.pricing[e][key])
      })
			// this.$set(this.selectedOrder.items[index], 'text', this.pricing[e].text);
			// this.$set(this.selectedOrder.items[index], 'unitPrice', this.pricing[e].unitPrice);
			// this.$set(this.selectedOrder.items[index], 'cc', this.pricing[e].cc);
			// this.$set(this.selectedOrder.items[index], 'code', this.pricing[e].code);
			this.$set(this.selectedOrder.items[index], 'id', e);
		},
		setOrderDate(theDate) {
			const date = parse(theDate, 'yyyy-MM-dd', new Date());
			if (this.dateType === 'order') {
				this.selectedOrder.date = date;
				this.selectedOrder.dateUTC = date.toISOString();
				this.selectedOrder.dateLocal = date.toLocaleString().substr(0, 10);
				if (this.selectedOrder.dateLocal.endsWith(',')) {
					this.selectedOrder.dateLocal = this.selectedOrder.dateLocal.slice(0, -1);
				}
			} else {
				this.selectedOrder.dueDate = date;
				this.selectedOrder.dueDateUTC = date.toISOString();
				this.selectedOrder.dueDateLocal = date.toLocaleString().substr(0, 10);
				if (this.selectedOrder.dueDateLocal.endsWith(',')) {
					this.selectedOrder.dueDateLocal = this.selectedOrder.dueDateLocal.slice(0, -1);
				}
			}

		},
		removeFromArray(array, i) {
			array.splice(i, 1);
		},
		addToLine() {
			this.selectedOrder.to.push('');
		},
		startNewOrder() {
			const id = uuid();
			this.newOrderIDs[id] = true;

			this.$set(this, 'selectedOrder', {
				date: new Date(),
				dateUTC: new Date().toISOString(),
				dateLocal: new Date().toLocaleDateString(),
				dueDateLocal: new Date().toLocaleDateString(),
				dueDateUTC: new Date().toISOString(),
				invoiceNum: `ems-${new Date().getTime()}`,
				orderID: id,
				to: [''],
				items: [
					{
						count: 1,
					}
				],
				note: 'Ship FEDEX 2 Day, To arrive 4.30 Take from EMS BIO Stock and Bill Inventory',
				terms: '50% w/ order, 50% minus credits net 45 day terms',
				shipping: {
					description: 'Shipping (2 day)',
					price: 0,
				},
				rebate: 0,
			});
		}
	},
	computed: {
		dateValue() {
			if (this.dateType === 'order') {
				return this.selectedOrder.dateUTC ? this.selectedOrder.dateUTC.substr(0, 10) : null;
			} else {
				return this.selectedOrder.dueDateUTC ? this.selectedOrder.dueDateUTC.substr(0, 10) : null;
			}
		},
		fileName() {
			if (!this.selectedOrder.companyID) return '';

			const company = this.companies[this.selectedOrder.companyID];

			return `${company.practiceName}-${this.selectedOrder.orderID}`;
		},
		ordersList() {
			const toReturn = Object.keys(this.orders).map(orderID => {
				const order = this.orders[orderID];
				const company = this.companies[order.companyID];
				return {
					order,
					company,
				}
			});

			return toReturn.sort((a, b) => {
				return a.order.date.seconds < b.order.date.seconds ? 1 : -1;
			})
		},
		shippingFormatted() {
			const toReturn = JSON.parse(JSON.stringify(this.selectedOrder.shipping));

			toReturn.price = toReturn.price ? this.formatMoney(toReturn.price) : 0;

			return toReturn
		},
		companyList() {
			return Object.keys(this.companies).map(key => {
				return {
					text: this.companies[key].practiceName,
					value: key,
				}
			});
		},
		selectedCompanyInfo() {
			return this.companies?.[this.selectedOrder.companyID];
		},
		// possibleCount() {
		//   return [...Array(50)].map((_, i) => { return { text: i + 1, value: i + 1 } });
		// },
		possibleProducts() {
			return Object.keys(this.pricing).map(key => {
				return {
					text: this.pricing[key].text,
					value: key,
				};
			});
		},
		hasSelectedOrder() {
			return !!(Object.keys(this.selectedOrder).length);
		}
	},
	watch: {
		'selectedOrder': {
			deep: true,
			handler() {
				if (!Object.keys(this.selectedOrder).length) return;
				this.calculateItems();
			},
		},
	}
};
</script>

<style lang="scss" scoped>
.meta-container {
	padding: 1rem;
	.order-internal-notes {
		margin-top: 1rem;
	}
}

.missing-error {
	margin-top: 2rem;
}

.orders {
	display: flex;
}

.list {
	width: 240px;
	max-height: calc(100vh - 72px);
	overflow: auto;
}

.order-item {
	flex-direction: column;
	align-items: flex-start;
	min-height: inherit;

	.sub {
		font-size: 12px;
		color: gray;
	}
}

.form {
	padding-top: 1rem;
	width: 300px;

	.tools {
		display: flex;
		justify-content: space-between;
		margin-bottom: 1rem;
	}

	.section-title {
		font-weight: bold;
		margin: 1rem 0;

		&--small {
			margin-top: 0;
		}
	}

	.field {
		display: flex;

		.count {
			margin-left: .5rem;
		}

		.remove {
			margin-left: .5rem;;
		}
	}
}

.list {
	padding: 1rem;
}

</style>
