<template>
	<div class="invoice">
		<div class="tools">
			<v-btn color="primary" @click="downloadPDF">Download PDF</v-btn>
			<v-switch label="Patient View" class="patient-toggle" v-model="patientViewToggle"/>
			<v-select :items="patientViewSelectItems" v-model="selectedPatientView" class="patient-toggle__select"
					label="Which Item" v-if="patientViewToggle"/>
		</div>
		<div class="toPrint" v-if="patientViewToggle" id="toPrint1" ref="invoice">
			<div class="invoice__header">
				<img
						alt="Invoice Logo"
						class="invoice__logo"
						src="@/assets/EMS-Bio_LogoFINAL_color_horiz_web.png"
				/>
			</div>
			<div class="invoice__body">
				<div class="contact">
					<div class="title">INVOICE</div>
					<p class="company">
						P: 801-810-6408<br>
						Fax: 385-215-8374<br>
						E: orders@emsbio.com<br>
						<!--            1333 N 430 E <br>-->
						<!--            Orem, UT 84097-->
					</p>
					<div class="title">Contact</div>
					<p class="personal">
						Natalyn Lewis<br>
						801-613-0634<br>
						natalyn@emsbio.com
					</p>

				</div>
				<div class="details">
					<div class="to">
						<div class="to-item" v-for="(line, i) in to" :key="i">{{ line }}</div>
					</div>
					<div class="product">
						<div class="invoice-num">Invoice No: {{ invoiceNum }}</div>
						<div class="date">Date: {{ date }}</div>
						<div class="patientName boxed" v-if="patientViewToggle">Patient Name:</div>
						<div class="date-of-birth boxed" v-if="patientViewToggle">Patient DOB:</div>
						<div class="date boxed">Date of Service:</div>
					</div>
					<div class="itemized">
						<table>
							<tr>
								<th>Description</th>
								<th>Quantity</th>
								<th>Unit Price</th>
								<th>Amount</th>
							</tr>
							<tr v-for="item in computedItems" :key="item.text">
								<td>{{ item.text }}</td>
								<td>{{ patientViewToggle ? 1 : item.count }}</td>
								<td>
									<div class="money"><span class="sym">$</span>{{ formatMoney(item.unitPrice) }}</div>
								</td>
								<td>
									<div class="money"><span class="sym">$</span>{{
											((patientViewToggle ? 1 : (item.count || 1)) * (item.unitPrice || 0)) ? formatMoney((patientViewToggle ? 1 : (item.count || 1)) * (item.unitPrice || 0)) : 0
										}}
									</div>
								</td>
							</tr>
							<tr class="blank">
								<td></td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<!--              <tr>-->
							<!--                <td></td>-->
							<!--                <td></td>-->
							<!--                <td>SubTotal</td>-->
							<!--                <td><div class="money"><span class="sym">$</span>{{computedTotals.subTotal}}</div></td>-->
							<!--              </tr>-->
							<tr class="break" v-if="!patientViewToggle">
								<td class="right smaller">50% w/order, 50% minus credits on 45 day terms</td>
								<td></td>
								<td></td>
								<td>
									<div class="money neg"><span class="sym">$</span>{{ computedTotals.rebate }}</div>
								</td>
							</tr>
							<tr class="break" v-if="!patientViewToggle">
								<td class="right">{{ shipping.description }}</td>
								<td></td>
								<td></td>
								<td>
									<div class="money"><span class="sym">$</span>{{ shipping.price }}</div>
								</td>
							</tr>
						</table>
						<div class="total-box">
							<table class="no-borders">
								<tr>
									<td class="spacer no-borders"></td>
									<td class="spacer no-borders"></td>
									<td class="boxed">
										<div class="money"><span class="sym"></span><span class="total">Total:</span>
											${{ computedTotals.grandTotal }}
										</div>
									</td>
								</tr>
							</table>

						</div>
					</div>
					<div class="thanks">
						<div class="signature__container" v-if="!patientViewToggle && grandTotal >= 15000">
							For all orders over $15,000, please return a signed copy of the invoice to authorize the payment amount.
							<br>
							<br>
							<div class="signature">
								<div class="line">_____________________________________________________________</div>
								<div class="text">
									<div class="by">Signed By:</div>
									<div class="date">Date:</div>
								</div>
							</div>
						</div>
						<p>Payment Terms: 50% due immediately, 50% due net 45 day terms</p>
						<p><small>Items on this invoice may be subject to a later rebate and thus may trigger reporting obligations
							to Federal health care programs</small></p>
						<div class="sticker-box">
							Product Used (attach product sticker here):
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- internal invoice -->
		<div class="toPrintInternal" v-else ref="invoice">
			<div class="invoice__header">
				<div class="logo">
					<img
							alt="Invoice Logo"
							class="invoice__logo"
							src="@/assets/EMS-Bio_LogoFINAL_color_horiz_web.png"
					/>
					<div class="address">
            1333 N 430 E
            Orem, UT 84097
					</div>
					<div class="dist-info">
						<div class="dist">Distributor: EMS BIO</div>
						<div class="contact">Contact: Brent Bingham</div>
						<div class="dist-num">Distributor Number: (385) 269-0478</div>
					</div>
				</div>
				<div class="invoiceData">
					<div class="call-out">INVOICE</div>
					<table>
						<thead>
						<tr>
							<th>Date</th>
							<th>#</th>
						</tr>
						</thead>
						<tfoot>
						<tr>
							<td>{{ date }}</td>
							<td>{{ invoiceNum }}</td>
						</tr>
						</tfoot>
					</table>
				</div>
			</div>

			<div class="customer-info">
				<div class="the-info">
					<div class="label">CUSTOMER INFO</div>
					<table>
						<tr>
							<td>Phone:</td>
							<td>{{ selectedCompanyInfo.practicePhone || selectedCompanyInfo.practiceMobilePhone }}</td>
						</tr>
						<tr>
							<td>E-mail:</td>
							<td class="break">{{ selectedCompanyInfo.practiceContactEmail }}</td>
						</tr>
						<tr v-if="email">
							<td>Requested E-mail:</td>
							<td class="break">{{ email }}</td>
						</tr>
					</table>
				</div>
				<div class="ship-to">
					<div class="label">SHIP TO</div>
					<div class="to">
						<div class="to-item" v-for="(line, i) in to" :key="i">{{ line }}</div>
					</div>
				</div>
			</div>

			<div class="invoice-deets">
				<table>
					<thead>
					<tr>
						<th>REP</th>
						<th>TERMS</th>
						<th>INVOICE DUE DATE</th>
					</tr>
					</thead>
					<tfoot>
					<tr>
						<td>INS</td>
						<td class="font-weight-bold custom-terms">{{ terms }}</td>
						<td>{{ dueDate }}</td>
					</tr>
					</tfoot>
				</table>
			</div>

			<div class="itemized">
				<table>
					<tr>
						<th>ITEM</th>
						<th>DESCRIPTION</th>
						<th>QTY</th>
						<th>INVOICE PRICE</th>
						<th>AMOUNT</th>
					</tr>
					<tr v-for="item in computedItems" :key="item.text">
						<td>{{ item.code }}</td>
						<td>{{ item.text }}</td>
						<td>{{ item.count }}</td>
						<td>
							<div class="money"><span class="sym">$</span>{{ formatMoney(item.unitPrice) }}</div>
						</td>
						<td>
							<div class="money"><span class="sym">$</span>{{
									(((item.count || 1)) * (item.unitPrice || 0)) ? formatMoney(((item.count || 1)) * (item.unitPrice || 0)) : 0
								}}
							</div>
						</td>
					</tr>
					<!--              <tr>-->
					<!--                <td></td>-->
					<!--                <td></td>-->
					<!--                <td>SubTotal</td>-->
					<!--                <td><div class="money"><span class="sym">$</span>{{computedTotals.subTotal}}</div></td>-->
					<!--              </tr>-->
					<tr class="break" v-if="!patientViewToggle">
						<td>Terms due</td>
						<td class="right smaller custom-terms">{{ terms }}</td>
						<td></td>
						<td></td>
						<td>
							<div class="money neg"><span class="sym">$</span>-{{ computedTotals.rebate }}</div>
						</td>
					</tr>
					<tr class="break" v-if="!patientViewToggle">
						<td>Shipping</td>
						<td class="right">{{ shipping.description }}</td>
						<td></td>
						<td></td>
						<td>
							<div class="money"><span class="sym">$</span>{{ shipping.price }}</div>
						</td>
					</tr>
				</table>
				<div class="total">
					<div class="label">ORIGINAL TOTAL</div>
					<div class="amount">${{ computedTotals.computedTotal }}</div>
				</div>
				<div class="total">
					<div class="label">ADJUSTED TOTAL</div>
					<div class="amount">${{ computedTotals.grandTotal }}</div>
				</div>
				<div class="total">
					<div class="label">BALANCE DUE PER TERMS</div>
					<div class="amount">${{ computedTotals.grandTotal }}</div>
				</div>
				<div class="sub-note">
					<small>Items on this invoice may be subject to a later rebate and thus may trigger reporting obligations to
						Federal health care programs.</small>
				</div>
				<div class="billing-contact">
					For Customer Billing and Order Questions Contact:<br>
					Natalyn Lewis <br>
					801-613-0634 <br>
					natalyn@emsbio.com <br>
				</div>

				<div class="special-note" v-if="note">
					<div class="label">SPECIAL INSTRUCTIONS FOR BIOLAB SCIENCES</div>
					<div class="note">
						{{ note }}
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
// import html2pdf from "html2pdf.js";
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas'

export default {
	name: 'Invoice',
	docs(v) {
		return {
			props: v.props,
			events: {},
			slots: {},
			components: v.components,
		};
	},
	props: {
		selectedCompanyInfo: {
			type: Object,
			description: 'Company info in order to generate the email etc.',
		},
		to: {
			type: Array,
			description: 'Array of strings that get added to the "to" section of the invoice details, each iteration is a new line',
		},
		date: {
			type: String,
			description: 'Date to show in invoice',
		},
		dueDate: {
			type: String,
			description: 'Due Date to show in invoice',
		},
		invoiceNum: {
			type: String,
			description: 'Invoice Number'
		},
		items: {
			type: Array,
			description: 'Array of invoice items with description, count, unitPrice for each...',
		},
		subTotal: {
			type: Number,
			description: '',
		},
		grandTotal: {
			type: Number,
			description: '',
		},
		totalCC: {
			type: Number,
			description: '',
		},
		rebate: {
			type: Number,
			description: ''
		},
		shipping: {
			type: Object,
			default() {
				return {
					description: 'Shipping (2 day)',
					price: 0,
				}
			},
			description: 'Object with description and price of shipping.',
		},
		fileName: {
			type: String,
			description: 'What the file should be called when you download',
		},
		note: {
			type: String,
			description: 'Special instruction note for the invoice',
		},
		terms: {
			type: String,
			description: 'Terms to show in invoice',
		},
		email: {
			type: String,
			description: 'An order specific email if needed',
		}
	},
	data() {
		return {
			patientViewToggle: false,
			selectedPatientView: null,
		};
	},
	methods: {
		downloadPDF() {

			const invoice = this.$refs.invoice;
			// const rects = invoice.getBoundingClientRect();

			html2canvas(invoice, {
				scale: 1,
			}).then(canvas => {
				document.body.appendChild(canvas);
				const imgdata = canvas.toDataURL('image/png');
				const doc = new jsPDF();
				doc.addImage(imgdata, 'PNG', 10, 10);
				doc.save(this.fileName + (this.selectedPatientView ? '_patient' : '') || 'emsbio_invoice' + (this.selectedPatientView ? '_patient' : '') + '.pdf');
				document.body.removeChild(canvas)
			})

			// const doc = new jsPDF('p', 'pt', 'a4');
			// doc.html(invoice, {
			// 	callback: function (doc) {
			// 		doc.save();
			// 	},
			// 	html2canvas: { scale: 595.26 / rects.width, },
			// 	filename: this.fileName + (this.selectedPatientView ? '_patient' : '') || 'emsbio_invoice' + (this.selectedPatientView ? '_patient' : ''),
			// });

			// html2pdf().set({
			// 	html2canvas: { scale: 2, },
			// 	// jsPDF: { orientation: 'landscape', }
			// }).from(this.$refs.invoice).save(this.fileName + (this.selectedPatientView ? '_patient' : '') || 'emsbio_invoice' + (this.selectedPatientView ? '_patient' : ''));
		},
		formatMoney(num) {
      if (num === 0) return 0;
			return Number((num || 1)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
		},
	},
	watch: {
		patientViewToggle() {
			this.selectedPatientView = null;
		},
	},
	computed: {
		computedTotals() {
			// if it's not a patient view then do normal things here
			if (!this.patientViewToggle) {
				return {
					subTotal: this.formatMoney(this.subTotal),
					grandTotal: this.formatMoney(this.grandTotal),
					rebate: this.formatMoney(this.rebate),
					computedTotal: this.formatMoney(this.grandTotal + this.rebate),
				};
			}

			const item = this.items.filter(item => {
				return item.id === this.selectedPatientView;
			})[0];

			if (item) {
				// const val = (item.count || 1) * (item.unitPrice || 0);
				// patient is only ever going to have one quantity in the patient view
				const val = 1 * (item.unitPrice || 0);
				const toSave = val ? this.formatMoney(val) : 0;
				return {
					subTotal: toSave,
					grandTotal: toSave,
				};
			} else {

				// if it gets here it's a patient and we only want to show one unit price.
				let oneItemCost = 0;

				this.items.forEach(item => {
					oneItemCost += item.unitPrice;
				});

				const val = this.formatMoney(oneItemCost)
				return {
					subTotal: val,
					grandTotal: val,
				};
			}

		},
		computedItems() {
			if (!this.patientViewToggle || !this.selectedPatientView) return this.items;

			return this.items.filter(item => {
				return item.id === this.selectedPatientView;
			});
		},
		patientViewSelectItems() {
			return this.items.map((item) => {
				return {
					text: item.text,
					value: item.id,
				};
			});
		}
	},
};
</script>

<style lang="scss" scoped>
.custom-terms {
	max-width: 350px;
}

.toPrintInternal {
	.label {
		font-weight: bold;
	}

	.invoice__header {
		display: flex;
		justify-content: space-between;
		margin-bottom: 2rem;
		align-items: flex-start;

		.logo {

			.dist-info {
				border: 1px solid;
				padding: 5px;
			}
		}

		.invoiceData {
			th {
				background: lightGray;
				border: 1px solid;
			}

			td {
				border: 1px solid;
				padding: .5rem;
			}

			.call-out {
				font-size: 28px;
				font-weight: bold;
				text-align: right;
				margin-bottom: -10px;
			}

			margin-top: 30px;
		}
	}

	.customer-info {
		display: flex;

		.the-info {
			flex: 1;
			max-width: 400px;
			table {
				width: 100%;

				td {
					border: 1px solid;
					text-align: center;
					padding: 4px;
				}
				.break {
					word-break: break-all;
				}
			}
		}

		.ship-to {
			flex: 1;
			margin-left: 1rem;

			.to {
				padding: 1rem;
				border: 1px solid;
				margin: 2px;
			}
		}
	}

	.invoice-deets {
		table {
			width: 100%;
			text-align: center;
			margin-top: 1rem;
			border-spacing: 0;
			border: 1px solid;

			th {
				background: lightgray;

				&:not(:last-child) {
					border-right: 1px solid;
				}
			}

			td {
				&:not(:last-child) {
					border-right: 1px solid;
				}
			}
		}
	}

	.itemized {
		table {
			width: 100%;
			border: 1px solid;
			margin-top: 1rem;
			border-spacing: 0;

			th {
				background: lightgray;

				&:not(:last-child) {
					border-right: 1px solid;
				}
			}

			td {
				padding: 5px;

				&:not(:last-child) {
					border-right: 1px solid;
				}
			}
		}

		.neg {
			color: red;
		}

		.total {
			display: flex;
			align-items: center;
			justify-content: flex-end;

			.amount {
				margin-left: 1rem;
				border: 1px solid;
				border-top: 0;
				padding: 5px;
				width: 109px;
			}
		}

		.billing-contact {
			max-width: 50%;
			margin-top: -100px;
			border: 1px solid;
			padding: 5px;
		}

		.sub-note {
			font-style: italic;
			display: flex;
			justify-content: flex-end;

			small {
				width: 50%;
				margin-top: 1rem;
				text-align: center;
				line-height: 1.2;
				font-style: italic;
				display: block;
			}
		}

		.special-note {
			margin-top: 1rem;

			.label {
				font-weight: bold;
				background: lightgray;
				border: 1px solid;
				padding: 5px;
				width: fit-content;
			}

			.note {
				border: 1px solid;
				margin: 5px 0;
				padding: 5px;
			}
		}
	}
}

.invoice {
	max-width: 900px;
	padding: 2rem;

	.boxed {
		padding: .5rem;
		border: 1px solid black;

		&:not(:last-child) {
			margin-bottom: .3rem;
		}
	}

	.sticker-box {
		padding: 2rem;
		background: lightgray;
		border-radius: 20px;
		border: 1px solid black;
		text-align: center;
	}

	.total-box {
		margin-top: .5rem;

		tr {
			display: flex;
		}

		td {
			flex: 1
		}

		.no-borders {
			border: none !important;
			border-right: none !important;
		}

		.spacer {
			//width: 50%;
		}

		.boxed {
			display: flex;
			border-right: 1px solid !important;

			.total {
				margin-right: 100px;
			}
		}
	}

	.tools {
		display: flex;
		justify-content: flex-end;
		align-items: center;

		.patient-toggle {
			margin-left: 2rem;

			&__select {
				max-width: 300px;
				margin-left: 2rem;
			}
		}
	}

	.toPrint {
		margin: 2rem;
	}

	&__header {
		display: flex;
		justify-content: center;
		margin-bottom: 2rem;
	}

	&__logo {
		max-width: 300px;
		margin-top: 1rem;
	}

	&__body {
		display: flex;

		.contact {
			margin-left: 0;
			margin-right: 1rem;
			min-width: 166px;

			.title {
				margin: 1rem 0;
			}

			.company {
				color: #262262;
			}

			.personal {
				margin: 1rem 0;
			}
		}

		.details {
			margin-right: 4rem;

			.to {
				margin: 1rem 0;
			}

			.product {
				margin: 1rem 0;
			}

			.itemized {
				margin: 1rem 0;

				table {
					width: 100%;
					border-spacing: inherit;
					border: 1px solid black;

					th {
						background: lightgray;
						padding: 0 .5rem;
						border-bottom: 1px solid black;
						font-weight: normal;
						text-align: center;
					}

					tr {
						&.blank {
							height: 27px;
						}

						&.break td {
							border-bottom: 1px solid black;
						}
					}

					td {
						&:first-child {
							border-left: 0;
						}

						border-right: 1px solid black;

						&:last-child {
							border-right: 0;
						}

						padding: 0 .5rem;
						//border: 1px solid #d1d3d4;

						&.right {
							text-align: right;

							&.smaller {
								font-size: 11px;
							}
						}

						.money {
							display: flex;
							justify-content: space-between;

							&.neg {
								color: red;
							}
						}
					}
				}
			}

			.thanks {
				margin: 1rem 0;

				small {
					line-height: 1.2;
					font-style: italic;
					display: block;
				}

				p {
					margin: 1rem 0;
				}
			}
		}
	}

	.signature {
		margin-left: 4rem;

		.line {
			margin: 2rem 0 1rem 0;
			display: flex;
			align-items: flex-end;
		}
	}

}
</style>
